<template>
  <top />
  <div class="f1">智慧服装应用系统介绍</div>
  <div class="f2 m-f1">
    “智慧服装管理系统”利用先进RFID数据载体，集合互联网、大数据营销、AI等技术将服装产品从订货管理、生产管理、仓储管理、门店管理、防伪防窜管理等连接到一起。能根据不同的环境状态相互协作，从而给用户带来最大程度的高效、便利、舒适的体验。提升供应链各环节的全面保障，实现产品的数据化、可视化的高效运营模式。
  </div>
  <card
    v-for="(item, index) in list"
    :key="index"
    :data="item"
    :reverse="index % 2"
    :shadow="index !== 0"
    :id="item.name"
  ></card>
  <div id="智慧试衣设备"></div>
  <dressing />
  <div id="系统数字化建设"></div>
  <digitizing />
  <!--  <div class="footer-br"></div>-->
  <common-footer />
</template>
<script>
import top from "./top";
import card from "./card";
import digitizing from "./digitizing";
import dressing from "./dressing";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    top,
    card,
    digitizing,
    dressing,
    CommonFooter,
  },
  data() {
    return {
      list: [
        {
          name: "智慧仓储系统",
          info: "服装RFID仓储管理系统促进公司管理模式的转变，从传统的依靠人工经验管理转变为依靠高科技的数字分析管理，从事后管理转变为事中管理、实时管理，加速了资金周转，提升供应链响应速度，增强公司的整体竞争能力。",
          image: require("@/assets/store/card/1.png"),
          mImage: require("@/assets/store/card/m1.png"),
          icon: require("@/assets/store/card/icon1.png"),
          bg: require("@/assets/store/card/bg1.png"),
          bgColor: "#fbfefe",
        },
        {
          name: "智慧门店系统",
          info: "智慧门店系统是集电子标签防盗技术、服装RFID无线射频识别技术、商用WIFI客流技术、视频及人脸识别技术为一体，为客户提供实时防损、客流统计、行为识别、数据分析的综合管理系统。",
          image: require("@/assets/store/card/2.png"),
          mImage: require("@/assets/store/card/m2.png"),
          icon: require("@/assets/store/card/icon2.png"),
          bg: require("@/assets/store/card/bg2.png"),
        },
        {
          name: "防窜防伪系统",
          info: "防窜防伪系统运用了先进的智能无线射频识别技术，每一个RFID芯片中都存储了全球唯一的ID编码。可实现单品/批量智能识别、实时防伪查询与验证、多维度的实时信息采集与智能化的全流程管理，具备生产管理、仓储管理、物流管理、全流程信息追溯、大数据分析、精准营销等功能。",
          image: require("@/assets/store/card/3.png"),
          mImage: require("@/assets/store/card/m3.png"),
          icon: require("@/assets/store/card/icon3.png"),
          bg: require("@/assets/store/card/bg3.png"),
          bgColor: "#fcfeff",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["scrollToSection"]),
  },
  mounted() {
    this.scrollToSection(this.$route.hash);
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  @if ($searchWidth<1100) {
    .m-f1 {
      margin-bottom: -3.2rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
