<template>
  <div class="f1">智慧试衣设备</div>
  <div class="f2 dressing-f2" style="">
    利于客户了解更多的商品信息，分析出受客户喜欢的样式、推送试衣搭配信息、分析试衣和销售比
  </div>
  <div class="image">
    <img :src="require('@/assets/store/dressing/1.png')" alt="智慧试衣设备" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .dressing-f2 {
    @if ($searchWidth<1100) {
    } @else {
      max-width: 7.46rem;
    }
  }

  .image {
    @if ($searchWidth<1100) {
      padding: 0 0.8rem;
      margin: 0 auto 2.6rem;
    } @else {
      max-width: 10.37rem;
      margin: 0 auto 0.5rem;
    }

    img {
      width: 100%;
      display: block;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
