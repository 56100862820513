<template>
  <div class="home-top">
    <img
      class="pc"
      :src="require('@/assets/store/top/bg.png')"
      alt="智能门店系统"
    />
    <img
      class="moblie"
      :src="require('@/assets/store/top/m-bg.png')"
      alt="智能门店系统"
    />
    <div class="main">
      <div class="content">
        <div class="image">
          <img :src="require('@/assets/store/top/1.png')" alt="智能门店系统" />
        </div>
        <div class="t">
          <div class="t1">智能门店系统</div>
          <div class="t2">系统可按业务需求定制</div>
        </div>
      </div>
      <a
        class="btn"
        target="_blank"
        href="https://clothing.cirteklink.com/shop/#/login"
        >服装门店系统试用</a
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .home-top {
    position: relative;

    @if ($searchWidth<1100) {
      margin-top: 3.2rem;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(51, 51, 51, 0.3);
    box-sizing: border-box;

    @if ($searchWidth<1100) {
      width: calc(100% - 1.6rem);
      padding: 1.1rem 0.9rem 1.15rem;
    } @else {
      padding: 0.35rem 1.13rem 0.42rem 1.22rem;
    }

    .content {
      //display: grid;
      //grid-column-gap: 0.89rem;
      //grid-template-columns: repeat(2, 4.25rem);
      display: flex;
      align-items: center;

      .image {
        flex-shrink: 0;

        @if ($searchWidth<1100) {
          width: 50%;
          img {
            width: calc(100% - 0.55rem);
            //display: block;
          }
        } @else {
          width: 4.25rem;
          margin-right: 0.64rem;
          img {
            width: 100%;
            //display: block;
          }
        }

        img {
          //width: calc(100% - 0.55rem);
          display: block;
        }
      }

      .t {
        flex-shrink: 0;

        @if ($searchWidth<1100) {
          width: 50%;
        } @else {
          width: 4.25rem;
        }
      }

      .t1 {
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #ffffff;
        text-align: center;
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid rgba(255, 255, 255, 0.4);
        width: 100%;
        margin-top: 0.3rem;
        padding: 0.1rem 0;
        position: relative;

        @if ($searchWidth<1100) {
          font-size: 1rem;
        } @else {
          font-size: 0.5rem;
        }

        &:after,
        &:before {
          content: "";
          position: absolute;
          width: 0.31rem;
          height: 0.31rem;
        }

        &:after {
          top: -0.01rem;
          left: -0rem;
          border-left: 1px solid #fff;
          border-top: 1px solid #fff;
        }

        &:before {
          bottom: -0.01rem;
          right: -0rem;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
        }
      }

      .t2 {
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #ffffff;
        text-align: center;

        @if ($searchWidth<1100) {
          font-size: 0.7rem;
          margin-top: 0.35rem;
        } @else {
          font-size: 0.3rem;
          margin-top: 0.2rem;
        }
      }
    }

    .btn {
      background: #5ec7b9;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      display: inline-block;
      text-decoration: none;

      @if ($searchWidth<1100) {
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        border-radius: 1rem;
        padding: 0 1.2rem;
        margin-top: 1rem;
      } @else {
        height: 0.64rem;
        line-height: 0.64rem;
        font-size: 0.24rem;
        border-radius: 0.32rem;
        padding: 0 0.26rem;
        margin-top: 0.26rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
