<template>
  <div
    class="container"
    :class="{ 'card-shadow': shadow }"
    :style="`background-color:${data.bgColor};background-image: url(${data.bg});`"
  >
    <div class="main" :class="{ reverse: reverse }">
      <div class="text">
        <div class="name">
          <img :src="data.icon" />
          {{ data.name }}
        </div>
        <div class="info">
          {{ data.info }}
        </div>
      </div>
      <div class="image">
        <img class="pc" :src="data.image" :alt="data.name" />
        <img class="moblie" :src="data.mImage" :alt="data.name" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background-repeat: no-repeat;
    background-size: cover;

    @if ($searchWidth<1100) {
      background-image: none !important;
    } @else {
      &.card-shadow {
        box-shadow: 0 -0.03rem 0.09rem 0 rgba(83, 177, 165, 0.2);
      }
    }
  }

  .main {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @if ($searchWidth<1100) {
      padding: 3.2rem 0.8rem;
      min-height: 5.32rem;
      flex-direction: column-reverse;
    } @else {
      max-width: 13.2rem;
      min-height: 4.32rem;

      &.reverse {
        flex-direction: row-reverse;
      }
    }

    .text {
      flex-shrink: 0;
    }

    .name {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        margin-top: 1rem;

        img {
          width: 1rem;
          margin-right: 0.4rem;
        }
      } @else {
        font-size: 0.3rem;
        margin-bottom: 0.2rem;

        img {
          width: 0.4rem;
          margin-right: 0.2rem;
        }
      }

      img {
        display: block;
      }
    }

    .info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        line-height: 1.25rem;
        // max-width: 7.17rem;
      } @else {
        font-size: 0.16rem;
        line-height: 0.25rem;
        max-width: 5.17rem;
      }
    }

    .image {
      flex-shrink: 0;

      @if ($searchWidth<1100) {
        // width: 12.95rem;
        padding-top: 0.35rem;
        margin: 0 -0.8rem;
      } @else {
        width: 5.05rem;
      }

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
